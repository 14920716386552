<template>
    <v-row>
      <v-col cols="12" style="padding:0px !important;">
        <v-carousel
              class="hidden-sm-and-down"
              :show-arrows="false"
              hide-delimiters
              interval="12000"
              height="500"
          >
              <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
              >
              <div class="overlay">
                  <v-row class="fill-height" style="padding-top:150px;">
                  <v-col cols="8" offset="1" style="color:#ffffff;">
                      <v-layout wrap class="text-h4" style="color:#ffffff;">
                      <b>{{ item.title }}</b>
                      </v-layout>
                      <p class="mt-5" style="font-size: 18px;">
                      {{ item.text }}
                      </p>
                      <!-- <v-layout wrap>
                      <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn>
                      </v-layout> -->
                  </v-col>
                  </v-row>
              </div>
              </v-carousel-item>
          
        </v-carousel>
        <v-carousel
              class="hidden-md-and-up"
              cycle
              :show-arrows="false"
              hide-delimiters
              height="200px"
              interval="10000"
          >
              <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              transition="fade"
              reverse-transition="fade"
              >
              <div class="overlay">
                  <v-row class="fill-height pt-5">
                  <v-col cols="8" offset="1" style="color:#ffffff;">
                      <v-layout wrap class="text-h6" style="color:#ffffff;">
                      {{ item.title }}
                      </v-layout>
                      <p>
                      {{ item.text }}
                      </p>
                      <v-layout wrap>
                      <v-btn rounded color="#72bd61" dark small>{{
                          item.btnText
                      }}</v-btn>
                      </v-layout>
                  </v-col>
                  </v-row>
              </div>
              </v-carousel-item>
        </v-carousel>
  
        <v-container fluid >
          <v-layout wrap>
                <v-col cols="12" align="center">
                  <h2><b>qikPharma Platform Terms and Conditions</b></h2>
                </v-col>
                <v-col cols="12">
                    <h4><b>Who Can Use the Platform</b></h4>
                    <p>
                        The Platform is intended for Manufacturers, distributors, retailers and end-users of pharmaceutical products who are at least 18 years old and legally authorized to enter into agreements.
                        By using the Platform, you confirm that you meet these eligibility requirements.
                    </p>

                    <h4><b>Creating an Account</b></h4>
                    <p>
                        Certain features of the Platform may require you to establish an account. During this process, you agree to provide accurate and up-to-date information. You are solely responsible for 
                        safeguarding the confidentiality of your account credentials and any activity that occurs under your account.
                    </p>
                    
                    <h4><b>Using the Platform Responsibly</b></h4>
                    <p>
                        We expect everyone who uses the Platform to do so lawfully and respectfully. This means adhering to all applicable laws and regulations, using the Platform only for legitimate purposes, 
                        and avoiding any actions that could disrupt or harm the Platform's operation. Impersonating others or misrepresenting yourself is strictly prohibited.
                    </p>

                    <h4><b>Responsibilities of Different User Groups</b></h4>
                    <p>
                        <ol>
                            <li><b>Retail Pharmacies:</b><br/>
                                <ol>
                                    <li>Ensure compliance with all relevant pharmacy laws and regulations, including licensing and record-keeping.</li>
                                    <li>Provide accurate details concerning your products and services.</li>
                                    <li>Fulfill orders promptly and uphold high customer service standards</li>
                                </ol>
                            </li>
                            <li><b>Manufacturers and Distributors:</b><br/>
                                <ol>
                                    <li>Provide accurate product information, including descriptions, pricing, and availability.</li>
                                    <li>Uphold product quality and safety, complying with all applicable laws and regulations regarding product labeling, marketing, and distribution.</li>
                                </ol>
                            </li>
                            <li><b>End-Users:</b><br/>
                                <ol>
                                    <li>Use the Platform for personal purposes only.</li>
                                    <li>Provide accurate information when placing orders.</li>
                                    <li>Comply with all applicable laws and regulations.</li>
                                </ol>
                            </li>
                        </ol>
                    </p>

                    <h4><b>Intellectual Property Rights</b></h4>
                    <p>
                        The Platform and all its content, including text, graphics, logos, images, software, and trademarks, are the property of qikPharma or its licensors and are protected by intellectual property laws. 
                        You are not permitted to modify, reproduce, distribute, or create derivative works based on the Platform or its content without prior written authorization from qikPharma.
                    </p>

                    <h4><b>Your Privacy Matters</b></h4>
                    <p>
                        We take your privacy seriously. Please refer to our Privacy Policy for details on how we collect, use, and disclose your personal information. 
                        By using the Platform, you acknowledge and consent to the practices described in the Privacy Policy.
                    </p>

                    <h4><b>Limitations of Liability</b></h4>
                    <p>
                        To the maximum extent permitted by law, qikPharma and its affiliated entities, officers, directors, employees, agents, and licensors will not be held liable for any indirect, incidental, special, consequential, 
                        or punitive damages arising from your access or use of the Platform. This includes any loss of profits, revenue, data, use, goodwill, or other intangible losses, whether incurred directly or indirectly.
                    </p>

                    <h4><b>Modifications and Termination</b></h4>
                    <p>
                        qikPharma reserves the right to modify or discontinue the Platform, or any features or services offered through the Platform, at any time without prior notice. 
                        We may also terminate or suspend your access to the Platform for any reason, without prior notice or liability.
                    </p>

                    <h4><b>Contact Us</b></h4>
                    <p>
                        If you have any questions or concerns about these Terms or the Platform, please do not hesitate to contact us at [letstalknigeria@qikpharma.com].
                    </p>

                </v-col>
          </v-layout>
  
          <v-layout wrap>
              <v-col cols="12" md="4" offset-md="2" align="left">
                      
              </v-col>
          </v-layout>
  
  
  
        </v-container>
  
      
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  
    data: () => ({
        slides: [
          {
            src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799387/Slider-2_crafkj.png",
            title: "Terms and Conditions",
            text: "Terms and Conditions establish the rules that govern your use of the Platform. By simply accessing or using the Platform, you signify your agreement to these Terms. If you disagree with any part of these Terms, please refrain from using the Platform.",
            btnText: "Learn How it Works",
            link: "#",
          },
        ],
    })
  };
  </script>
  
  <style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
  }
  
  .box1 {
    background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
    border-radius: 10px;
    color: #ffffff;
  }
  
  .box2 {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    color: #171a17;
  }
  
  .barcodeTitle {
    color: #000000;
    font-size: 16px;
    text-align: center;
  }
  
  .barcodeText {
    color: #c2c2c2;
    font-size: 12px;
    margin-top: 15px;
    text-align: center;
  }
  
  .centerImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
  
  p {
    line-height: 1.6;
  }
  
  li {
    line-height: 1.6;
  }
  
  </style>
  